export default {
    state: {
        menue: {
            id: null
        },
    },
    mutations: {
        setMenue: (state, menue) => state.menue = menue,
    },
    getters: {
        /*
        orderItemSum: state => state.orderItems.reduce((sum, item) => sum += (item.count * item.price), 0),
        orderSum: (state, getters, rootState, rootGetters) => getters.orderItemSum + rootGetters.getters.paymentFee,

         */
        orderItems: (state, getters, rootState, rootGetters) => rootGetters.cart(state.menue.id).map(item => {
            return { id: item.id, quantity: item.count || 0, menueId: state.menue.id};
        }),
        /**
         * The Big order object!
         * @returns {{}}
         */
        order: (state, getters, rootState, rootGetters) => ({
            items: getters.orderItems,
            payment: rootState.payment,
            delivery: rootGetters.deliveryObject,
            user: rootState.userData.user,
            comment: rootState.userData.comment,
            menue: state.menue,
            amount: {
                cart: (state.menue ? rootGetters.cartSum(state.menue.id) : null),
                delivery: 0,
                payment: (state.menue ? rootGetters.paymentFee(state.menue.id) : null),
                total: (state.menue ? (rootGetters.cartSum(state.menue.id)  + rootGetters.paymentFee(state.menue.id)) : 0)
            }
        }),
        postOrder: (state, getters) => {
            let o = {...getters.order};
            delete o.menue;

            return o;
        }
    }
}
