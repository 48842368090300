<template>
    <div>
        <b-form-row>
            <b-col cols="8">
                <b-form-group>
                    <validation-provider rules="required" v-slot="validationContext">
                        <b-form-input v-model.trim="address.street"
                                      name="street"
                                      id="street"
                                      placeholder="Straße"
                                      :state="getValidationState(validationContext)"
                        />
                    </validation-provider>
                </b-form-group>
            </b-col>
            <b-col>
                <validation-provider rules="required" v-slot="validationContext">
                    <b-form-group>
                        <b-form-input v-model="address.number" :state="getValidationState(validationContext)" placeholder="Hausnummer"/>
                    </b-form-group>
                </validation-provider>
            </b-col>
        </b-form-row>
        <b-form-row>
            <div class="col-6">
                <validation-provider name="PLZ" rules="required|length:5|deliveryRange" v-slot="validationContext">
                    <b-form-group>
                        <b-form-input v-model="address.zip" name="PLZ" :state="getValidationState(validationContext)" placeholder="PLZ"></b-form-input>
                        <b-form-invalid-feedback>
                    <span v-if="Object.keys(validationContext.failedRules).indexOf('deliveryRange') >= 0">
                        Die Postleitzahl liegt momentan nicht im Liefergebiet.<br>
                        <b-link v-b-modal.delivery-detail>Hier</b-link> findest du das aktuelle Liefergebiet.
                    </span>
                            <span v-else>{{validationContext.errors[0]}}</span>
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
            </div>
            <div class="col">
                <b-form-group>
                    <b-form-input disabled v-model="address.city" placeholder="Ort"></b-form-input>
                </b-form-group>
            </div>
        </b-form-row>
    </div>
</template>

<script>
    export default {
        props: {
            value: null,
        },
        data() {
            return {
                address: {
                    street: this.value.street,
                    zip: this.value.zip,
                    city: this.value.city,
                    number: this.value.number
                }
            }
        },
        methods: {
            getValidationState({dirty, validated, valid = null}) {
                return dirty || validated ? valid : null;
            }
        },
        watch: {
            address: {
                handler(value) {
                    this.$emit('input', value)
                }, deep: true
            }
        }
    }
</script>