<template>
    <div class="category-item">
        <h2>{{ category.name }}</h2>
        <p>
            {{ category.description }}
            <span v-if="category.quantity">
                {{ category.quantity }}
            </span>
        </p>

        <food v-for="item in items" :key="item.id" :item="item" :menue="menue" />
    </div>
</template>

<script>
import Food from './Food';
export default {
    name: 'Category',
    components: {
        Food
    },
    props: ['category', 'items', 'menue']

}
</script>

<style lang="scss">
.category-item {
    h2 {
        color: $primary;
        font-family: $lemon-font;
        font-size: 3rem;
        border-bottom: 1px solid darkgrey;
        margin-bottom: 0.1rem;
    }

    &>p {
        font-size: 0.9rem;
    }
}
</style>
