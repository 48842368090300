<template>
    <div id="error">
      <div class="col-md-10">
        <div class="alert" :class="alertClass" role="alert">
            <h4 class="alert-heading" v-if="code === '406'">
                <icon icon="sun"></icon>
                Wochenende!
            </h4>
            <h4 class="alert-heading" v-else>
                <icon icon="circle-x"></icon>
                Sorry - Da ist leider was schief gegangen
            </h4>

            <p v-if="code === '1526724919'">
                Die von dir benutzte <strong>E-Mail Adresse</strong> konnte leider keiner teilnehmenden Firma zugeordnet werden. Ist deine Firma noch nicht dabei? Oder hast du eine
              private E-Mail Adresse genutzt? Schreib uns gerne eine <a :href="`mailto:${email}`">E-Mail</a> mit deinen Daten, damit wir dich künftig beliefern können!
            </p>

            <p v-else-if="code === '1526901012'">
                Eines oder mehrere Gerichte aus deiner Bestellung sind leider mittlerweile ausverkauft. Bitte reduziere die Menge oder wähle ein anderes Gericht.
            </p>

            <p v-else-if="code === '1534154127'">
                Die Bestellfrist für heute ist leider abgelaufen.
            </p>

            <p v-else-if="code === '1527153572'">
                Bei der Validierung deiner Eingaben gab es Probleme - bitte <strong><router-link to="/checkout">gehe zurück zum Formular</router-link></strong> und überprüfe deine Eingaben.
            </p>

            <p v-else-if="code === '1622387969'">
              Es tut uns leid aber leider können wir heute deine Firma nicht beliefern.
            </p>

            <p v-else-if="code === '404'">
                Die Speisekarte für heute konnte leider nicht geladen werden. Eventuell gibt es gerade technische Probleme oder heute ist kein Gericht bestellbar. Komm morgen wieder!
            </p>

            <div v-else-if="code === '406'">
                <p>Heute bleibt die Küche leider kalt. Am Montag findet Ihr hier wieder leckere und hausgemachte Speisen für eure Mittagspause.</p>

                <p>Wir wünschen ein schönes und erholsames Wochenende!</p>
                <hr>
                <p>Liebe Grüße</p>
            </div>

            <p v-else>
                Es ist ein unbekannter Fehler aufgetreten - das tut uns leid. Die Technik ist automatisch informiert worden.
            </p>
            <div v-show="showButtons">
                <hr>
                <p>
                    Du kannst zurück zur Speisekarte gehen oder mit uns <a :class="textClass" :href="`mailto:${email}`" target="_blank">Kontakt aufnehmen</a>.
                </p>
            </div>
        </div>

        <div v-show="showButtons" class="btn-group">
            <b-button variant="outline-primary" :to="`/menue/${$store.state.order.menue.id}`">Zurück zur Karte</b-button>
            <b-button variant="outline-secondary" to="/checkout">Zurück zum Warenkorb</b-button>
        </div>
      </div>
    </div>
</template>
<script>
import Icon from './Icon'
import tenant from '@/tenant'

export default {
    props: {
        code: {type: String, default: -1}
    },
    components: {
        Icon
    },
    computed: {
        email() {
          return tenant.ENV.EMAIL;
        },
        alertClass() {
            if (this.code === '1526724919' || this.code === '1534154127' || this.code === '1622387969') {
                return 'alert-warning';
            } else if (this.code === '404') {
                return 'alert-info';
            } else if (this.code === '406') {
                return 'alert-success';
            } else {
                return 'alert-danger';
            }
        },
        textClass() {
            return this.alertClass.replace(/alert-/g, 'text-') + ' link';
        },
        showButtons() {
            return this.code !== '404' && this.code !== '406';
        }
    }
}
</script>
<style lang="scss">
    #error {
        .alert {
            margin-top: 3.5rem;
            p {
                margin-bottom: 0;
            }

            a.link {
                font-weight: bold;
            }
        }
    }
</style>
