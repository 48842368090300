<template>
    <div id="checkout">
        <div class="col">
            <h2 class="app-headline">Jetzt Essen bestellen!</h2>

            <b-alert :show="!canOrder">
                Deine Bestellung ist bisher leer - gehe
                <router-link to="/">zur Speisekarte</router-link>
                und lege Gerichte in den Warenkorb.
            </b-alert>

            <steps  v-show="canOrder"></steps>

            <router-view v-show="canOrder" />
        </div>
        <div id=cart-wrapper class="col-auto">
            <cart :menue=$store.state.currentMenue />
        </div>
    </div>
</template>

<script>
import Steps from "./Checkout/Steps";
import Cart from './Cart'
export default {
    components: {Steps, Cart},
    computed: {
        canOrder() {
            return this.$store.getters.cart.length > 0
        }
    }
}
</script>

<style lang="scss">
#checkout {
    .tools {
        border-top: 1px solid $border-color;
        padding: 1rem 0;
    }

    .text-primary {
        font-weight: bold;
    }
}
</style>
