<template>
    <b-list-group-item class="cart-item">
        <div class="">
            <h5 class="">
                <small>{{item.count}} x</small>
                {{item.food.name}}
            <b-badge variant="primary" pill>{{item.price * item.count | money}}</b-badge>
            </h5>
            <b-button-group size="sm">
                <b-button variant="outline-secondary" @click="addToCart({item, menue})">+</b-button>
                <b-button variant="outline-secondary" @click="decreaseCart(item)">-</b-button>
                <b-button variant="outline-danger" @click="removeFromCart(item)">&times;</b-button>
            </b-button-group>
        </div>
    </b-list-group-item>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
    props: {
        item: {type: Object, required: true},
        menue: {type: Object, required: true}
    },
    methods: mapMutations(['decreaseCart', 'removeFromCart', 'addToCart'])
}
</script>
<style lang="scss">
.list-group-item.cart-item {
    border: none;

    h5 small {
        color: darkgrey;
    }


    .badge-primary {
        color: white;
        letter-spacing: 1px;
    }
}



</style>
