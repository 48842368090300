<template>
    <span ref="icon" :class="iconClass" :title="iconTitle" aria-hidden="true">
        <b-tooltip :disabled="!tooltip" :target="() => $refs.icon"></b-tooltip>
    </span>


</template>
<script>
export default {
    props: {
        title: {type: String, required: false, default: ''},
        icon: {type: String, required: true},
        tooltip: {type: Boolean, required: false, default: false}
    },
    computed: {
        iconClass() {
            if (this.icon === 'vegan') {
                return 'icon-vegan';
            } else if (this.icon === 'veg') {
                return 'icon-veg';
            } else {
                return 'oi oi-' + this.icon;
            }
        },
        iconTitle() {
            switch (this.icon) {
                case 'vegan': return 'Vegan';
                case 'veg': return 'Vegetarisch';
                default: return this.title;
            }
        }
    }
}
</script>

<style lang="scss">
    .icon-vegan,
    .icon-veg {
        display: inline-block;
        width: 20px;
        height: 16px;
        background: url("../assets/icon_vegan.svg") no-repeat center center;
    }

    .icon-veg {
        background: url("../assets/icon_veg.svg") no-repeat center center;
    }
</style>
