import Vue from 'vue'
import Router from 'vue-router'
import Menue from './components/Menue'
import Checkout from './components/Checkout'
import Success from './components/Success'
import Error from './components/Error'

import PersonalData from "./components/Checkout/PersonalData";
import Delivery from "./components/Checkout/Delivery";
import Payment from "./components/Checkout/Payment";
import Confirm from "./components/Checkout/Confirm";
import NoMenue from "@/components/Error/NoMenue";
Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'lunch',
            component: Menue
        },{
            path: '/menue/:id',
            name: 'lunch-detail',
            component: Menue
        },{
            path: '/checkout',
            component: Checkout,
            children: [{
                path: '',
                name: 'checkout-1',
                component: PersonalData
            }, {
                path: 'delivery',
                name: 'checkout-2',
                component: Delivery
            }, {
                path: 'payment',
                name: 'checkout-3',
                component: Payment
            },{
                path: 'confirm',
                name: 'checkout-4',
                component: Confirm
            }]
        },{
            path: '/success',
            name: 'success',
            component: Success,
            props: true
        },{
            path: '/error/404',
            component: NoMenue
        },{
            path: '/error/:code',
            name: 'error',
            component: Error,
            props: true
        }
    ]
})
