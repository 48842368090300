import axios from 'axios';

const HTTP = axios.create({
    baseURL: process.env.VUE_APP_TRACKING_URL
})

const defaultParams = {
    idsite: 12,
    rec: 1,
    url: "https://order.lunchbox.koeln"
}

export default {
    async trackOrder(totalSum) {
        // do not track for now
        /*let target = {
            idgoal: 1,
            revenue: totalSum / 100
        }
        await HTTP.get('piwik.php', {
            params: {...defaultParams, ...target}
        });
         */
    }
}
