import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import moment from 'moment'
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate";
import de from 'vee-validate/dist/locale/de.json';
import * as rules from "vee-validate/dist/rules";
import {validator} from "./data/valid-zip";
import {addScript} from "@/paypal";

addScript();
moment.locale('de');
moment.updateLocale('de', {
    calendar: {
        lastDay: '[Gestern] (DD.MM.)',
        sameDay: '[Heute]',
        nextDay: '[Morgen] (DD.MM.)',
        lastWeek: '[Letzten] dddd',
        nextWeek: '[Nächsten] dddd (DD.MM.)',
        sameElse: 'L'
    }
})
localize("de", de);

extend('deliveryRange', {...validator});
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.filter('money', value => {
    const format = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })
    return format.format(value / 100);
})

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

new Vue({
    router,
    store,
    components: {
        ValidationProvider
    },
    render: h => h(App)
}).$mount('#app')
