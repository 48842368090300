<template>
    <div class="food-list">
        <h1>{{menue.name}}</h1>
        <p class="valid-string">
            {{menue.validString}}
        </p>

        <menu-selector class="my-4" v-show="$store.state.availableMenues.length > 1" />

        <category
                :category="category.category"
                :items="category.foodItems"
                :key="category.id"
                :menue=menue
                v-for="category in categories" />
    </div>
</template>

<script>
import Category from './Category';
import MenuSelector from "./Menu/MenuSelector";


export default {
    name: 'FoodList',
    props: {
        menue: {required: true}
    },
    components: {
        Category,
        MenuSelector
    },
    computed: {
        categories() {
            return this.menue.items || [];
        },
        /*menu() {
            return this.$store.getters.currentMenu || {
                name: 'Lunch Lieferservice',
                validString: 'Lade…'
            }
        }*/
    }
}
</script>
<style lang="scss">
    h1 {
        font-family: $lemon-font;
        text-align: center;
        font-size: 3.025rem;
        border-bottom: 1px solid black;
    }
    .valid-string {
        text-align: center;
        font-family: $lemon-font;
        font-size: 1.4rem;
        margin-bottom: 2rem;
    }
</style>
