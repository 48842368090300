import store from './store/index'
import router from './router'
import tenant from './tenant'
import Vue from "vue";

const paypalMoney = input => (input/100).toFixed(2);
const paypalAmount = input => ({
    currency_code: 'EUR',
    value: paypalMoney(input)
})

const itemMapper = item => ({
    name: item.food.name,
    unit_amount: paypalAmount(item.price),
    quantity: item.count
});

const createOrder = (data, actions) => {
    const order = store.getters.order
    let orderObject = {
        application_context: {
            brand_name: 'Lunchcloud Online Bestellung',
            shipping_preference: (store.getters.homeDelivery ? 'SET_PROVIDED_ADDRESS' : 'NO_SHIPPING')
        },
        purchase_units: [{
            amount: {
                ...paypalAmount(order.amount.total),
                breakdown: {
                    shipping: paypalAmount(order.amount.payment),
                    item_total: paypalAmount(order.amount.cart)
                }
            },
            items: store.getters.cart(order.menue.id).map(itemMapper)
        }]
    };

    if(store.getters.homeDelivery) {
        orderObject.purchase_units[0].shipping = store.getters.paypalShippingAddress;
    }

    return actions.order.create(orderObject);
}

const onApprove = async (data) => {
    store.commit('startLoading');
    try {
        /*const authorization = await actions.order.authorize();
        const authorizationID = authorization.purchase_units[0]
            .payments.authorizations[0].id;

         */

        store.commit('setPayPalResponse', {orderID: data.orderID})
        await store.dispatch('placeOrder');
    } catch (e) {
        console.error(e);
        await router.replace({name: 'error', params: {code: '500'}});
    }
    store.commit('loadingFinish');
}


const addScript = () => {
    const s = document.createElement('script');
    s.src = `https://www.paypal.com/sdk/js?client-id=${tenant.ENV.PAYPAL_CLIENT}&currency=EUR&disable-funding=credit,card,venmo,sepa,bancontact,eps,giropay,ideal,mybank,p24,sofort&debug=false&locale=de_DE&intent=capture`
    s.onload = () => {
        const style = {
            shape: 'pill',
            label: 'buynow'
        }

        paypal.Buttons({style});

        Vue.component('PaypalButton', paypal.Buttons.driver('vue', Vue));
    }
    document.body.appendChild(s);
}


export {createOrder, onApprove, addScript}