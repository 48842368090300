const ZipList = new Map([
    ["Altstadt", [50667, 50675, 50670, 50672, 50674, 50676, 50677, 50678]],
    ["Ehrenfeld", [50823, 50825, 50827, 50829]],
    ["Nippes", [50733, 50735, 50737, 50739]],
    ["Kalk", [51103]],
    ["Mühlheim", [51063, 51065]],
    ["Gremberghoven",  [51149]],
    ["Gremberg", [51105]],
    ["Widdersdorf", [50859]],
    ["Deutz", [50675]]
]);

const rawList = Array.from(ZipList.values()).reduce((result, group) => result.concat(group), []);
const validator = {
    getMessage(field) {
        return `ZIP invalid ${field}`
    },

    validate: val => rawList.indexOf(parseInt(val)) > -1
}

const forTable = () => {
    let res = [];
    ZipList.forEach((value, key) => {
        res.push({
            stadtteil: key,
            Postleitzahl: value.join(', ')
        });
    })

    return res;
}

export {forTable, validator}