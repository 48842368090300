<template>
    <b-modal title="Liefergebiet" :id="id" ok-only body-class="p-0">
        <p class="p-3">
            Wenn ihr in einem der nachfolgenden PLZ-Gebiete wohnt und eure Firma
            bereits von uns beliefert wird, könnt ihr euer Essen bequem nach Hause bestellen
        </p>

        <b-table striped hover :items="items"></b-table>
    </b-modal>
</template>

<script>
import {forTable} from "../../data/valid-zip";

export default {
    props: {
        id: null
    },
    data: () => ({
        items: forTable()
    })
}
</script>