import axios from 'axios';
import tenant from './tenant';

export default axios.create({
    baseURL: `${tenant.ENV.ENDPOINT}/v1`
})

const v2 = axios.create({
    baseURL: `${tenant.ENV.ENDPOINT}/v2`
})

export {v2};
