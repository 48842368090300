import moment from 'moment';


export default class {

    id = null;
    items = [];
    name;
    #orderDeadline;

    constructor(data) {
        Object.assign(this, data);
        this.validFrom = moment(data.validFrom);
        this.validTo = moment(data.validTo).add(1, 'day');
        this.#orderDeadline = moment(data.orderUntil);
    }

    get isValidToday() {
        return this.isValidAtDate(moment());
    }

    isValidAtDate(date) {
        return date.isBetween(this.validFrom, this.validTo, undefined, '[]')
    }

    isOrderableAtDate(date) {
        return moment().isBefore(this.#getDeadlineForDate(date))
    }

    get nextOrderDeadline() {
        const now = moment();
        const upcoming = this.allOrderDeadlines.find(date =>  now.isBefore(date));

        return (upcoming ? upcoming : this.allOrderDeadlines.pop())
    }

    get allOrderDeadlines() {
        const iterateDay = this.validFrom.clone(),
            deadlines = [],
            dayCount = this.validTo.diff(this.validFrom, 'days')

        for (let i = 0; i < dayCount; i++) {
            deadlines.push(this.#getDeadlineForDate(iterateDay));
            iterateDay.add(1, 'day');
        }

        return deadlines;
    }

    get orderableDeadlines() {
        const now = moment();
        return this.allOrderDeadlines.filter(date => now.isBefore(date))
    }

    #getDeadlineForDate(date) {
        return date.clone()
            .seconds(this.#orderDeadline.seconds())
            .minutes(this.#orderDeadline.minutes())
            .hours(this.#orderDeadline.hours());
    }
}
