<template>
    <div id="cart">
        <h3>
            <icon icon="cart" title="Bestellung" /> Meine Bestellung
        </h3>

        <div>
            <b-list-group>
                <cart-item v-for="item in cart" :key="item.id + item.count" :item="item" :menue=menue />

                <b-list-group-item v-show="paymentFee(menue.id) > 0" class="cart-item px-0">
                    <div class="d-flex text-muted">
                        <span>Servicepauschale</span>
                        <span class="ml-auto">{{ paymentFee(menue.id) | money}}</span>

                    </div>
                </b-list-group-item>
            </b-list-group>


            <div class="mov" :v-show="minimumOrder > 0" :class="{notreached: !minimumOrderReached}">
                <p>Mindestbestellwert:</p>
                <p>{{ minimumOrder | money }}</p>
            </div>

            <div class="total">
                <p>Gesamt:</p>
                <p> {{totalSum | money }}</p>
            </div>

            <b-button variant="primary" :disabled=!canBeOrdered @click="startCheckout" size="lg" v-show="!isInCheckout">
                Jetzt bestellen
            </b-button>

            <!--
            <p class="order-until" :v-show="menue.nextOrderDeadline.isAfter(now)">
                Bestellugen sind noch möglich für {{now.to(menue.nextOrderDeadline, true)}} (bis {{menue.nextOrderDeadline.format('HH:mm')}} Uhr).
            </p>
            -->

          <b-alert variant="warning" :show="minimumOrder > 0 && !minimumOrderReached">
            Du hast leider den Mindestbestellwert noch nicht erreicht
          </b-alert>

        </div>

        <b-alert variant="warning" :show="menue.nextOrderDeadline.isBefore(now)">
            <p>Von dieser Karte kann leider nicht mehr bestellt werden - Wähle eine andere Karte.</p>
        </b-alert>

    </div>
</template>
<script>
import CartItem from './CartItem'
import Icon from './Icon'
import {mapGetters} from 'vuex'
import moment from 'moment'
import tenant from '../tenant'
export default {
    props: {
        menue: { required: true}
    },
    data() {
        return {
            now: moment(),
            interval: null,
            minimumOrder: tenant.feature.minimumOrderValue
        }
    },
    components: {
        Icon,
        CartItem
    },
    computed: {
        ...mapGetters(['cartSum', 'canBeOrderedToday', 'paymentFee']),
        canBeOrdered() {
            return this.cart.length > 0 && this.menue.nextOrderDeadline.isAfter(this.now) && this.minimumOrderReached
        },
        isInCheckout() {
            return this.$route.name && this.$route.name.startsWith('checkout-');
        },
        cart() {
            return this.$store.getters.cart(this.menue.id);
        },
        totalSum() {
            return this.$store.getters.cartSum(this.menue.id) + this.paymentFee(this.menue.id);
        },
        minimumOrderReached() {
            return this.$store.getters.cartSum(this.menue.id) >= this.minimumOrder
        }
    },
    created() {
        this.interval = setInterval(() => {
            this.now = moment();
        }, 1000);
    },
    methods: {
        startCheckout() {
            this.$store.dispatch('transferToOrder', this.menue);
            this.$router.push({name: 'checkout-1'})
        }
    },
    destroyed() {
        clearInterval(this.interval);
    }
}
</script>
<style lang="scss">
#cart {
    /*position: fixed;
    right: 15px;
    width: calc(100% - 30px);
    */

    h3 {
        line-height: 3.6rem;
        font-size: 1.5rem;
        border-bottom: 1px solid $border-color;
        color: $primary;
    }

    .mov {
      justify-content: space-between;
      display: flex;

      &.notreached {
        color: #fd7e14;
      }
    }

    .total {
        border-top: 2px solid $primary;
        border-bottom: 1px solid $border-color;
        display: flex;
        justify-content: space-between;
        p {
            margin: 1rem 0;
            color: $primary;
            font-weight: bold;
            font-size: 1.1rem;
        }
    }

    .order-until {
        margin-top: 1rem;
        font-size: .8rem;
    }

    .alert {
        margin-top: 1rem;
    }
}
</style>
