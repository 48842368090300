export default {
    state: {
        method: 'cash',
        additionalData: null,
    },
    getters: {
        paypalFee: (s, g, r, rootGetters) => menueId => { return rootGetters.cartSum(menueId) < 1000 ? 40 : 0},
        paymentFee: (state, getters, rootState, rootGetters) => menueId => {
            return (state.method === 'paypal') ? getters.paypalFee(menueId) : 0
        },
        usePaypal: state => state.method === 'paypal',
        hasPaymentAccount: (s, g, rootState) => { return rootState.userData.settings.hasAccount }
    },
    mutations: {
        setPaymentMethod: (state, method) => { state.method = method },
        setPayPalResponse: (state, resp) => state.additionalData = resp
    },
    actions: {}
}