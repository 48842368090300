<template>
    <validation-observer ref="observer" v-slot="{ invalid }">
    <b-form @submit.stop.prevent="submit">
        <h3>Bestelldaten</h3>
        <b-form-group id="name">
            <b-form-row>
                <b-col>
                    <validation-provider rules="required" v-slot="validationContext">
                    <b-form-input id="firstName" type="text" v-model="firstName" :state="getValidationState(validationContext)" placeholder="Vorname" />
                    </validation-provider>
                </b-col>
                <b-col>
                    <validation-provider rules="required" v-slot="validationContext">
                    <b-form-input id="lastName" type="text" v-model="lastName" :state="getValidationState(validationContext)" placeholder="Nachname" />
                    </validation-provider>
                </b-col>
            </b-form-row>
        </b-form-group>
        <b-form-group id="email" description="Bitte gib deine Firmen E-Mail Anschrift an. Anhand dieser können wir deine Bestellung deiner Firma zuordnen">
            <validation-provider rules="required|email" v-slot="validationContext">
                <b-form-input id="emailInput" type="email" required v-model="email" :state="getValidationState(validationContext)" placeholder="nachname@firma.de" />
            </validation-provider>
        </b-form-group>
        <b-form-group id="phone" description="Optional - für Rückfragen">
                <b-form-input id="phoneInput" type="text" v-model="phone" placeholder="Telefonnummer" />
        </b-form-group>
        <b-form-group>
            <b-form-checkbox switch v-model="saveData">Deine Daten auf diesem Gerät fürs nächste Mal speichern?</b-form-checkbox>
        </b-form-group>

        <div class="d-flex w-100 justify-content-center align-items-center flex-column">
            <p>
                <b-button variant="primary" type="submit" :disabled="invalid" size="lg">Lieferung wählen</b-button>
            </p>
            <p>
                <b-link class="text-muted" :to="`/menue/${menueId}`">Zurück zur Speisekarte</b-link>
            </p>
        </div>
    </b-form>
    </validation-observer>
</template>

<script>
export default {
    data: () => ({
        userInfo: {}
    }),
    computed: {
        user() {
            return this.$store.state.userData.user;
        },
        menueId() {
          return this.$store.state.order.menue.id;
        },
        firstName: {
            get() { return this.user.firstName },
            set(v) { this.commit('firstName', v)}
        },
        lastName: {
            get() { return this.user.lastName },
            set(v) { this.commit('lastName', v)}
        },
        email: {
            get() { return this.user.email },
            set(v) { this.commit('email', v)}
        },
        phone: {
            get() { return this.user.phone },
            set(v) { this.commit('phone', v)}
        },
        saveData: {
            get() { return this.$store.state.userData.saveData },
            set(v) { this.$store.commit('saveUserData', v)}
        }
    },
    methods: {
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        commit(field, value) {
            this.$store.commit('setUserDataField', {field, value})
        },
        submit() {
            this.$router.push({name: 'checkout-2'});
        }
    },
    beforeRouteLeave(to, from ,next) {
        this.$store.dispatch('updateUser');
        next();
    }
}
</script>