<template>
    <div id="app" class="">
        <div id="loading" v-show="$store.state.loading">
            <loading />
        </div>

        <router-view class="row justify-content-md-center"/>
    </div>
</template>

<script>
import Loading from './components/Loading'

export default {
    name: 'App',
    components: {
        Loading,
    },
}
</script>

<style lang="scss">

@import "~bootstrap/scss/bootstrap";
@import "~open-iconic/font/css/open-iconic-bootstrap";

    #loading {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba($green,.3);
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
    }

.app-headline {
    color: $primary;
    font-family: $lemon-font;
    font-size: 3rem;
    border-bottom: 1px solid $border-color;
    margin-bottom: 1rem;
}

    #cart-wrapper {
        border-left: 1px solid $border-color;
        width: 370px;
        position: relative;
    }

.btn-primary.btn-lg {
    color: white;
    margin-top: 1rem;
    text-transform: uppercase;
}
</style>
