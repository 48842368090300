<template>
  <div>
    <b-list-group  v-if="dates.length < 5">
        <b-list-group-item class="d-flex" v-for="date in dates" :key="date.format()">
          <b-form-radio v-model="deliveryDay" :value=date>{{date.calendar()}}</b-form-radio>
        </b-list-group-item>
    </b-list-group>

    <b-form-select v-model="deliveryDay" :options="options" v-else class="mb-4" />
  </div>
</template>

<script>
export default {
  name: "DeliveryDatePicker",
  props: {
    dates: { required: true },
    value: { required: true }
  },
  data() {
    return {
      deliveryDay: null
    }
  },
  created() {
    if (!this.value) {
      this.deliveryDay = [...this.dates].shift()
    } else {
      this.deliveryDay = this.value;
    }
  },
  computed: {
    options() {
      return this.dates.map(date => ({
        text: date.calendar(),
        value: date
      }))
    }
  },
  watch: {
    deliveryDay(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>

<style scoped>

</style>