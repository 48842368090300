<template>
  <div class="d-flex">
    <span class="navbar-text">Wähle eine Karte:</span>
    <ul class="nav nav-pills ml-2">
      <li class="nav-item" v-for="menu in menus" :key="menu.id">
        <router-link :to="`/menue/${menu.id}`" class="nav-link" :class="{'active': activeMenu.id === menu.id}">{{ getDate(menu) }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Menue from "@/models/menue";
export default {
  name: 'menu-selector',
  data() {
    return {}
  },
  computed: {
    menus() {
      return this.$store.state.availableMenues;
    },
    activeMenu() {
      return this.$store.state.currentMenue;
    }
  },
  methods: {
    getDate(menue) {
      return (new Menue(menue)).nextOrderDeadline.calendar();
    }
  }
}
</script>
