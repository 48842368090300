<template>
    <div class="food">
        <div class="headline">
            <h4>{{ item.food.name }}
                <icon :icon="icon" tooltip />
                <span v-if="!orderable" class="not-orderable">(Für heute leider ausverkauft)</span>
            </h4>
            <h4>{{ item.price | money }}</h4>
        </div>
        <div class="food-description">
            <p class="description" :class="{'not-orderable': !orderable}" v-html="item.food.description"></p>
            <p class="tools">
                <b-button-group>
                    <b-button variant="primary" @click="addToCart" :disabled="!orderable" v-b-tooltip.hover title="Zur Bestellung hinzufügen">
                        <icon icon="cart" />
                    </b-button>
                    <b-button v-b-modal="modalId" variant="outline-secondary" v-b-tooltip.hover title="Zutaten und Allergene">
                        <icon icon="info" />
                    </b-button>
                </b-button-group>
            </p>
        </div>

        <b-modal :id="modalId" :title="item.food.name" @cancel="addToCart" :ok-only="!orderable">
            <p><strong class="text-primary">Allergene:</strong>
                <span v-for="additive in item.food.additives" :key="additive.name">
                    {{additive.name}},
                </span>
            </p>
            <p><strong class="text-primary">Zutaten:</strong> {{item.food.ingredients}}</p>
            <icon :icon="icon" tooltip />
            <template slot="modal-cancel">
                <icon title="Warenkorb" icon="cart"/> Zur Bestellung hinzufügen
            </template>
        </b-modal>

    </div>
</template>

<script>
import Icon from './Icon'
export default {
    name: 'Food',
    props: ['item', 'menue'],
    components: {
        Icon
    },
    computed: {
        modalId() {
            return 'ingredients' + this.item.id;
        },
        orderable() {
            return this.item.canBeOrdered;
        },
        icon() {
            switch (this.item.food.nutritionType) {
                case 2: return 'vegan';
                case 1: return 'veg';
                default: return '';
            }
        }
    },
    methods: {
        addToCart() {
            this.$store.commit('addToCart', {item: this.item, menue: this.menue});
        }
    }
}
</script>
<style lang="scss">
.food {
    h4 {
        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: bold;
        //font-family: $font-family-roboto;
        margin-bottom: 0;
    }

    .headline {
        display: flex;
        h4:first-child {
            margin-right: auto;
        }
    }

    .food-description {
        display: flex;

        .description {
            margin-right: auto;
        }
    }

    .not-orderable {
        color: $border-color;
        text-transform: none;
    }

    .tools {
        padding-top: .5rem;
    }
}
</style>
