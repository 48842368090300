<template>
    <div id="menu-wrapper">
        <div id="menu" class="col">
            <food-list :menue=$store.state.currentMenue />
        </div>

        <div id="cart-wrapper" class="col-auto">
            <cart :menue=$store.state.currentMenue />
        </div>
    </div>
</template>

<script>
import FoodList from './FoodList'
import Cart from './Cart'
import store from '../store'

const loadMenu = async (to, _, next) => {
    try {
        const availableMenues = await store.dispatch('loadAvailableMenues');

        if (availableMenues.length === 0) {
          console.warn('No current/future menue found');
          next('/error/404');
          return;
        }

        const menueId = (to.params.id ? to.params.id : availableMenues[0].id)
        const menue = await store.dispatch('loadLunchById', menueId);
        store.commit('setCurrentMenue', menue);
        next();
    } catch(e) {
        next(e)
    }
}
export default {
    components: {
        FoodList,
        Cart
    },
    beforeRouteEnter: loadMenu,
    beforeRouteUpdate: loadMenu,
}
</script>
