<template>
    <validation-observer v-slot="{invalid}">
    <b-form @submit.stop.prevent="submit">
        <h3>Lieferung</h3>

        <div class="row mb-4">

            <div class="col-10 m-auto ">
                <p><strong>Für wann möchtest du bestellen?</strong></p>

              <validation-provider rule="required">
                <delivery-date-picker v-model="deliveryDay" :dates="deliveryDates" />
              </validation-provider>
            </div>
            <div class="col-10 m-auto ">
                <p><strong>Wohin möchtest du deine Bestellung geliefert haben?</strong></p>


                <b-list-group>
                    <validation-provider rule="required" v-slot="validationContext">
                    <b-list-group-item class="d-flex">
                        <b-form-radio v-model="method" value="office" aria-labelledby="method-feedback">Lieferung ins Büro</b-form-radio>
                    </b-list-group-item>
                    <b-list-group-item v-if="hasHomedelivery">
                        <div class="d-flex">
                            <b-form-radio v-model="method" value="home" aria-labelledby="method-feedback">Lieferung nach Hause</b-form-radio>
                            <b-link class="ml-auto" v-b-modal.delivery-detail>Unser Liefergebiet</b-link>
                        </div>

                        <b-collapse :visible="homeDelivery">
                            <delivery-address-form v-model="address" v-if="homeDelivery" class="mt-4" />
                            <delivery-detail id="delivery-detail"></delivery-detail>
                        </b-collapse>
                    </b-list-group-item>
                    </validation-provider>
                </b-list-group>

                <b-form-invalid-feedback id="method-feedback">
                    Bitte wählen Sie eine Liefermethode
                </b-form-invalid-feedback>
            </div>
        </div>

        <div class="d-flex w-100 justify-content-center align-items-center flex-column">
            <p>
                <b-button variant="primary" :disabled="invalid" type="submit" size="lg">Zahlungsart</b-button>
            </p>
            <p>
                <b-link class="text-muted" to="/checkout/">Persönliche Daten bearbeiten</b-link>
            </p>
        </div>
    </b-form>
    </validation-observer>
</template>

<script>
import DeliveryAddressForm from "./DeliveryAddressForm";
import DeliveryDetail from "./DeliveryDetail";
import {mapGetters} from 'vuex'
import DeliveryDatePicker from "@/components/Ui/DeliveryDatePicker";
import tenant from '@/tenant'

export default {
    name: 'delivery',
    components: {DeliveryDatePicker, DeliveryDetail, DeliveryAddressForm},
    mounted() {
      if (!this.hasHomedelivery) {
        this.method = 'office';
      }
    },
    computed: {
        ...mapGetters(['homeDelivery']),
        method: {
            get () { return this.$store.state.delivery.method },
            set (v) { this.$store.commit('setDeliveryMethod', v)}
        },
        address: {
            get () { return this.$store.state.delivery.address },
            set (v) { this.$store.commit('setDeliveryAddress', v)}
        },
        deliveryDay: {
            get() { return this.$store.state.delivery.day },
            set(v) { this.$store.commit('setDeliveryDate', v)}
        },
        deliveryDates() {
            return this.$store.state.order.menue.orderableDeadlines;
        },
        menue() { return this.$store.state.order.menue},
        hasHomedelivery() {
          return tenant.feature.homeDelivery;
        }
    },
    methods: {
        submit() {
            //this.$store.commit('setDeliveryData', {method: this.method, address: this.address});
            this.$router.push({name: 'checkout-3'});
        }
    }
}
</script>
