import {v2} from "../api";


export default {
    state: {
        user: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
        },
        saveData: false,
        comment: null,
        settings: {
            hasAccount: null
        },
    },
    mutations: {
        setUserData: (state, userData) => state.user = userData,
        setUserDataField: (state, {field, value}) => state.user[field] = value,
        saveUserData: (state, save) => state.saveData = save,
        setAccountState: (state, hasAccount) => state.settings.hasAccount = hasAccount,
        setComment: (state, comment) => state.comment = comment
    },
    getters: {
        fullName: state => `${state.user.firstName} ${state.user.lastName}`
    },
    actions: {
        async updateUser({commit, state}) {
            try {
                const response = await v2.post('account/info',state.user.email);
                commit('setAccountState', response.data.companyAllow || response.data.hasAccount);
            } catch (e) {
                commit('setAccountState', false)
            }
        }
    }
}