import Vue from 'vue'

export default {
    state: {
        items: []
    },
    mutations: {
        addToCart(state, {item, menue}) {
            if (item.canBeOrdered) {
                const index = state.items.indexOf(item);
                if (index > -1) {
                    item.count++;
                    state.items.splice(index, 1, item);
                } else {
                    item.count = 1;
                    item.menueId = menue.id;
                    state.items.push(item);
                }
            }
        },

        decreaseCart(state, item) {
            const index = state.items.indexOf(item);
            if (index > -1) {
                if (item.count > 1) {
                    item.count--;
                    Vue.set(state.items, index, item);
                } else {
                    state.items.splice(index, 1);
                }
            }
        },

        removeFromCart(state, item) {
            const index = state.items.indexOf(item);
            if (index > -1) {
                state.items.splice(index, 1);
            }
        },

        clearCart(state) {
            state.items = [];
        },
    },
    actions: {
        transferToOrder({commit}, menue) {
            commit('setMenue', menue);
        }
    },
    getters: {
        cartSum: (state, getters) => menueId => getters.cart(menueId).reduce((sum, item) => sum += (item.count * item.price), 0),
        cart: state => menueId => state.items.filter(item => item.menueId == menueId) || [],
    }
}
