<template>
    <div id="success">
      <div class="col-12">
        <h2 class="app-headline">Guten Appetit!</h2>
      </div>
      <div class="col-10">

        <b-alert variant="success" show>
            <p>Vielen Dank! Deine Bestellung ist eingegangen.</p>
            <hr>
            <p class="mb-0">
                Die Lieferung kommt gegen <strong>{{time}} Uhr</strong>. Guten Appetit!
            </p>
        </b-alert>
      </div>


    </div>
</template>
<script>
export default {
    props: ['time']
}
</script>
