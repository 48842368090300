<template>
    <div class="stepper my-4">
        <div class="steps d-flex justify-content-between">
            <div v-for="step in steps">
                <router-link exact-active-class="active" :to="step.route" disabled v-if="step.step <= currentStep">
                    {{step.label}} <b-badge pill v-if="step.done">&checkmark;</b-badge>
                </router-link>

                <span class="inactive" v-else>
                    {{ step.label }}
                </span>
            </div>

        </div>
        <b-progress :max="100" :value="progress" class="mb-3" variant="primary" />

    </div>
</template>

<script>
export default {
    name: 'steps',
    data() {
        return {
            steps: [{
                route: '/checkout',
                label: 'Deine Daten',
                step: 1
            }, {
                route: '/checkout/delivery',
                label: 'Lieferung',
                step: 2,
            }, {
                route: '/checkout/payment',
                label: 'Bezahlung',
                step: 3
            },{
                route: '/checkout/confirm',
                label: 'Prüfen & Bestellen',
                step: 4
            }]
        }
    },
    computed: {
        currentStep() {
            return parseInt(this.$route.name.replace('checkout-', ''))
        },
        progress() {
            switch (this.currentStep) {
                case 1: return 5;
                case 2: return 33;
                case 3: return 60;
                case 4: return 100;
                default: return 0;
            }
        }
    },
}
</script>

<style lang="scss">
.stepper {
    .steps {
        a.active {
            font-weight: bold;
        }
    }
}
</style>