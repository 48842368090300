/**
 * Needs to be a CommonJS format
 *
 * @type {{lunchbox: {env: {ENDPOINT: string, PAYPAL_CLIENT: string}, cssPrefix: {}, _overrides: {production: {env: {ENDPOINT: string, PAYPAL_CLIENT: string}}, staging: {env: {ENDPOINT: string}}}}, mittagsservice: {env: {ENDPOINT: string}, cssPrefix: {}}}}
 */

const data = require('./data/tenants.json');

module.exports = {
    get tenant() {
        return process.env.VUE_APP_TENANT;
    },

    get mode() {
        return process.env.VUE_APP_MODE || 'production'
    },

    get feature() {
        return data[this.tenant]['features'];
    },

    //ENV: {},
    get CSS() {
      return data[this.tenant]['cssPrefix']
    },

    get ENV() {
        // load defaults
        const config = data[this.tenant],
            env = {};
        if (!config) {
            throw Error('Did not find config for tenant ' + this.tenant);
        }

        for (const [key, value] of Object.entries(config.env)) {
            env[key] = value
        }
        // overrides
        if (config._overrides && config._overrides[this.mode] && config._overrides[this.mode].env) {
            for (const [key, value] of Object.entries(config._overrides[this.mode].env)) {
                env[key] = value
            }
        }

        return env;
    }
}
