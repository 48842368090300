<template>
    <validation-observer v-slot="{invalid}">
        <div class="row">
            <div class="col-10 mx-auto">
              <h5 class="text-primary">Kontrolliere noch einmal alles</h5>
                <b-list-group class="m-4 confirmation">
            <b-list-group-item class="d-flex">
              <p class="">Du bestellst für <span class="underline">{{ deliveryDay.calendar() }}</span>

                <span v-for="item in items" :key="item.id" class="">
                  {{item.count}} mal <span class="text-primary">{{item.food.name}}</span>
                </span>

                <span v-if="$store.getters.homeDelivery" class="underline">
                  zu Dir nach Hause
                    <address v-html="homeShippingAddress" />
                </span>
                <span v-else class="underline">
                  in dein Büro
                </span>

                </p>
                <edit-link class="ml-auto" to="/checkout/delivery" />
            </b-list-group-item>

            <b-list-group-item class="d-flex">
              <p>
                Du bezahlst insgesamt <span class="text-primary">{{ totalSum | money }}</span>
                <span v-show="paymentFee > 0" class="">&nbsp; inklusive einer Servicegebühr von {{ paymentFee |money }}</span>
                <span v-if="$store.state.payment.method === 'cash'" class="underline">
                        in Bar bei Lieferung.
                    </span>
                <span v-else-if="$store.state.payment.method === 'account'" class="underline">
                        über Dein Kundenkonto
                    </span>
                <span v-else class="underline">
                        gleich mit PayPal
                    </span>
              </p>

              <edit-link to="/checkout/payment" class="ml-auto"  />
            </b-list-group-item>
            <b-list-group-item class="d-flex">
                <p>
                  Bei Fragen wenden wir uns an <span class="underline">{{fullName}}</span> unter
                  <a :href="`mailto:${$store.state.userData.user.email}`">
                    <b-icon-envelope-open />
                    {{$store.state.userData.user.email}}
                  </a>
                  <span v-show="$store.state.userData.user.phone.length > 0" class="underline">
                    oder <span class="text-primary"><b-icon-phone /> {{$store.state.userData.user.phone}}</span>
                </span>
                </p>

                <edit-link to="/checkout" class="ml-auto" style="min-width: 60px" />
            </b-list-group-item>
            <b-list-group-item>
                <h5 class="text-primary">
                    Sonst noch was?
                </h5>
                <b-form-group>
                    <b-form-textarea v-model="comment" placeholder="…Deine Anmerkungen"></b-form-textarea>
                </b-form-group>
            </b-list-group-item>
        </b-list-group>
            </div>

            <div class="col-7 mx-auto">
                <div class="d-flex justify-content-center align-items-center flex-column">
                    <div v-if="usePaypal">
                        <paypal-button ref="button" :createOrder="createOrder" :onApprove="onApprove"/>
                        <p class="text-center text-muted">
                            <b-icon-arrow-up />
                            Um Deine Bestellung abzuschließen
                            klicke auf den PayPal Button. Du wirst dann zu PayPal weitergeleitet.<br>
                            Bei Bezahlung
                            über die PayPal Funktion gelten die allgemeinen
                            Geschäftsbedingungen der PayPal (Europe) S.à r.l. et Cie, S.C.A.
                        </p>


                    </div>
                    <div v-else>
                        <b-button variant="primary" :disabled="invalid" type="submit" size="lg" @click="$store.dispatch('placeOrder')">
                            Jetzt zahlungspflichtig bestellen
                        </b-button>
                    </div>

                    <p class="text-muted mt-4">
                        Es gelten im Übrigen unsere <a href="https://www.lunchbox.koeln/agb-lieferdienst" target="_blank">Lieferservice AGB</a> und die
                        <a href="https://www.lunchbox.koeln/datenschutz" target="_blank">Daten&shy;schutz&shy;bestim&shy;mungen</a>.
                    </p>
                </div>
            </div>


        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import {createOrder, onApprove} from "../../paypal";
import editLink from '../Ui/EditLink'

export default {
    data: () => ({
    }),
    methods: {
        createOrder,
        onApprove
    },
    components: {
        editLink
    },
    mounted() {
    },
    computed: {
      ...mapGetters(['usePaypal', 'cart', 'cartSum', 'fullName', 'homeShippingAddress', 'order', 'deliveryDay']),
      menueId() {
        return this.$store.getters.order.menue.id
      },
      items() {
        return this.$store.getters.cart(this.menueId);
      },
      paymentFee() {
        return this.$store.getters.order.amount.payment
      },
      totalSum() {
        return this.$store.getters.order.amount.total
      },
      comment: {
            get() { return this.$store.state.userData.comment },
            set(v) { this.$store.commit('setComment', v) }
      }
    }
}
</script>

<style lang="scss">
    .confirmation {
        font-size: 120%;

        h5 {
            font-size: 120%;
        }

        address {
            margin-bottom: 0;
          font-size: 80%;
        }
    }

    .underline {
      border-bottom: 2px solid var(--primary);
    }
</style>
