import Vue from 'vue'
import Vuex from 'vuex'
import HTTP from '../api'
import {v2} from "@/api";
import VuexPersistence from 'vuex-persist'
import router from '../router'
import tracking from '../tracking';
import moment from 'moment';
import payment from "./payment"
import delivery from "./delivery"
import userData from './userdata'
import order from './order'
import cart from './cart'
import Menue from '../models/menue';
import tenant from '../tenant'
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: state => {
        if (state.userData.saveData) {
            return {
                userData: {
                    user: state.userData.user,
                    saveData: state.userData.saveData
                },
                // cart: state.cart,
                // order: state.order,
                delivery: {
                    address: state.delivery.address,
                    method: state.delivery.method
                }
            }
        } else {
            return {}
        }
    }
})

export default new Vuex.Store({
    plugins: [vuexLocal.plugin],
    modules: {
        payment,
        delivery,
        userData,
        cart,
        order
    },
    strict: process.env.NODE_ENV !== 'production',
    state: {
        menueData: {},
        loading: 0,
        availableMenues: {},
        currentMenue: null
    },
    mutations: {
        setLunchData(state, data) {
            Vue.set(state.menueData, data.id, new Menue(data));
        },
        setAvailableMenues(state, data) {
            for (const activeMenu of data) {
                activeMenu.date = moment(activeMenu.validFrom);
                Vue.set(state.availableMenues, activeMenu.id, activeMenu);
            }
        },
        setCurrentMenue(state, menu) {
            state.currentMenue = menu;
        },

        loadingFinish(state) {
            state.loading--
        },
        startLoading(state) {
            state.loading++
        },
    },
    actions: {
        /*async setActiveMenu({state, commit, dispatch}, activeMenu) {
            if (!state.lunchData.hasOwnProperty(activeMenu)) {
                dispatch('loadLunchById', activeMenu);
            }
            commit('setCurrentMenuId', activeMenu);
        },*/

        /**
         * Load Menu Data
         * @param commit
         * @return {Promise<void>}
         */
        async loadAvailableMenues({commit, state}) {
            if (Object.keys(state.availableMenues).length > 0){
                return state.availableMenues;
            }

            commit('startLoading')
            try {
                const responseActive = await HTTP.get('lunch/active');

                commit('setAvailableMenues', responseActive.data);
                commit('loadingFinish')
                return responseActive.data;
            } catch (e) {
                commit('loadingFinish')
                throw e;
            }
        },

        async loadLunchById({commit, state}, id) {
            // if we already have it do not reload
            if (state.menueData[id]) {
                return state.menueData[id];
            }

            commit('startLoading');
            try {
                const response = await HTTP.get(`lunch/${id}`);
                commit('setLunchData', response.data);
                commit('loadingFinish')
                return state.menueData[id];
            } catch (e) {
                commit('loadingFinish')
                throw e;
            }
        },

        /**
         * Place the actual order!
         * @param state
         * @param userData
         * @return {Promise<void>}
         */
        async placeOrder({commit, state, getters}) {
            commit('startLoading')
            try {
                const response = await v2.post('order', getters.postOrder);

                tracking.trackOrder(getters.cartSum);
                commit('clearCart');
                await router.push({name: 'success', params: {time: response.data.estimtatedTime}});
            } catch (e) {
                console.log(e);
                if (e.response && e.response.data.code) {
                    await router.replace({name: 'error', params: {code: '' + e.response.data.code}});
                } else {
                    await router.replace({name: 'error', params: {code: '500'}});
                }
            }
            commit('loadingFinish')
        }
    },
    getters: {
        isLoading: state => state.loading > 0,
        totalSum: () => 0,

        canBeOrderedToday: state => {
            if (state.currentMenue && state.currentMenue.orderUntil) {
                return moment().isBefore(state.currentMenue.orderUntil);
            } else {
                return false;
            }
        },
    }

})
