<template>
  <div id="error">
    <div class="alert alert-primary" role="alert">
      Momentan sind keine Gerichte bestellbar. Komm doch später wieder!
    </div>
  </div>
</template>

<script>
export default {}
</script>