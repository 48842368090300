<template>
    <validation-observer v-slot="{invalid}">
        <h3>Zahlungsmethoden</h3>
        <validation-provider>
        <b-list-group class="mx-4">
            <b-list-group-item class="d-flex w-100">
                <b-icon-wallet class="mr-3" font-scale="2" variant="primary" />
                <b-form-radio v-model="method" value="cash">
                    <strong>Bar</strong> bei Lieferung
                </b-form-radio>
                <span class="ml-auto">
                    <b-badge variant="success">Gratis</b-badge>
                </span>
            </b-list-group-item>
            <b-list-group-item id="payment-account" class="d-flex" :disabled="!hasPaymentAccount" >
                <b-icon-shield-shaded class="mr-3" font-scale="2" :variant="(hasPaymentAccount) ? 'primary': null" />
                <b-form-radio v-model="method" value="account" :disabled="!hasPaymentAccount">
                    Auf das <strong>Kundenkonto</strong>
                    <b-form-text v-show="!hasPaymentAccount">
                        Möchtet Ihr
                        Euch mit einem Kundenkonto bei uns registrieren? Dann schreibt uns
                        einfach über Eure Firmen-Mailadresse eine <a :href="`mailto:${email}?subject=Kundenkonto`">E-Mail</a>
                        mit dem Stichwort "Kundenkonto" und Euren Daten
                        (vollständiger Name und Rechnungsadresse). Am Ende eines Monats
                        erhaltet Ihr eine Rechnung und könnt den Betrag  auf unser Konto
                        überweisen.
                    </b-form-text>
                </b-form-radio>
                <span class="ml-auto">
                    <b-badge variant="primary">Gratis</b-badge>
                </span>
            </b-list-group-item>
            <b-list-group-item class="d-flex" v-if="hasPaypal">
                <p class="mr-2">
                    <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/pp-acceptance-small.png" alt="PayPal Acceptance">
                </p>
                <b-form-radio v-model="method" value="paypal">
                    <strong>PayPal</strong>
                    <b-form-text>Am Ende der Bestellung wirst du zu PayPal weitergeleitet. Ab einem Bestellwert von
                    10 € berechnen wir keine Servicepauschale.</b-form-text>
                </b-form-radio>
                <span class="ml-auto">
                    <b-badge variant="primary" v-if="paypalFee === 0">Gratis</b-badge>
                    <b-badge pill v-else>{{ paypalFee | money }}</b-badge>
                </span>
            </b-list-group-item>
        </b-list-group>
        </validation-provider>
        <div class="d-flex w-100 justify-content-center align-items-center flex-column">
            <p>
                <b-button variant="primary" to="/checkout/confirm" size="lg">Prüfen & bestellen</b-button>
            </p>
            <p>
                <b-link class="text-muted" :to="{name: 'checkout-2'}">Lieferdaten bearbeiten</b-link>
            </p>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex';
import tenant from '@/tenant';
export default {
    computed: {
      ...mapGetters(['hasPaymentAccount']),
      paypalFee() {
          return this.$store.getters.paypalFee(this.$store.state.order.menue.id);
      },
      hasPaypal() {
        return tenant.feature.paypal;
      },
      email() {
        return tenant.ENV.EMAIL
      },
      method: {
          get () {
              return this.$store.state.payment.method
          },
          set(v) {
              this.$store.commit('setPaymentMethod', v)
          }
      }
    }
}
</script>

<style lang="scss">
    #payment-account.disabled {
        pointer-events: auto;
    }
</style>