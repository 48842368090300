import moment from 'moment'

const emptyAddress = {
    street: null,
    number: null,
    zip: null,
    city: 'Köln'
};

export default {
    strict: process.env.NODE_ENV !== 'production',
    state: {
        method: null,
        address: emptyAddress,
        day: null
    },
    mutations: {
        setDeliveryMethod: (state, method) => state.method = method,
        setDeliveryAddress: (state, address) => state.address = address,
        setDeliveryDate: (state, date) => state.day = moment(date)
    },
    getters: {
        deliveryDay: state => state.day,
        homeDelivery: (state) => state.method === 'home',
        deliveryObject: (state, getters) => {
            let delivery = {
                method: state.method,
                day: (getters.deliveryDay ? getters.deliveryDay.format() : null )
            }

            if (getters.homeDelivery) {
                delivery.address = state.address;
            }

            return delivery;
        },
        homeShippingAddress: state => `
            ${state.address.street} ${state.address.number}<br />
            ${state.address.zip} ${state.address.city}
        `,
        paypalShippingAddress: (state, getters, rootState, rootGetters) => ({
            name: {
                full_name: rootGetters.fullName
            },
            address: {
                address_line_1: `${state.address.street} ${state.address.number}`,
                address_line_2: '',
                admin_area_2: state.address.city,
                postal_code: state.address.zip,
                country_code: 'DE',
            }
        })
    }
}
